import { graphql } from 'gatsby';
import React from 'react';
import BlogContent from '../components/BlogContent';
import GeneralHead from '../components/generalHead';
import Layout from '../components/layout';
import SEO from '../components/seo';

const BlogPost = ({ data }) => {
  const { blog } = data;

  return (
    <Layout forceShowHeader forceShowFooter forceAnnouncement>
      <BlogContent blogContent={blog} />
    </Layout>
  );
};

export const Head = ({
  data: {
    blog: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export const query = graphql`
  query($id: String!) {
    blog: datoCmsBlogPost(id: { eq: $id }) {
      id
      internalName
      indexable
      slug
      headHtml
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      schemaMarkup {
        id
        schema
      }
      metaTags {
        title
        description
        image {
          gatsbyImageData
          url
        }
      }
      category {
        name
        tagColor {
          hex
        }
        tagBackground {
          hex
        }
      }
      image {
        gatsbyImageData(
          imgixParams: {
            fm: "webp"
            auto: "compress"
            maxW: 1080
            fit: "clip"
            q: 35
          }
        )
        fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
          ...GatsbyDatoCmsFluid
        }
        alt
        url
      }
      title
      author {
        slug
        bio {
          blocks
          links
          value
        }
        funFact
        role
        firstName
        lastName
        headshot {
          image {
            gatsbyImageData
            url
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
      publishDate
      content {
        value
        blocks {
          __typename
          ... on DatoCmsImage {
            id: originalId
            image {
              gatsbyImageData(
                imgixParams: {
                  fm: "webp"
                  auto: "compress"
                  maxW: 1080
                  fit: "clip"
                  q: 35
                }
              )
              fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
                ...GatsbyDatoCmsFluid
              }
              alt
              url
              title
              width
              height
            }
          }
          ... on DatoCmsNewsletter {
            id: originalId
            heading
            hubspotFormId
          }
          ... on DatoCmsVideo {
            id: originalId
            video {
              url
              thumbnailUrl
              title
            }
          }
        }
        links {
          __typename
          ... on DatoCmsWebsitePage {
            id: originalId
            slug
            metaTags {
              title
            }
          }
          ... on DatoCmsBlog {
            id: originalId
            slug
            metaTags {
              title
            }
          }
          ... on DatoCmsBlogPost {
            id: originalId
            slug
            title
          }
          ... on DatoCmsBlogCategory {
            id: originalId
            name
          }
          ... on DatoCmsCaseStudiesPage {
            id: originalId
            slug
            metaTags {
              title
            }
          }
          ... on DatoCmsCaseStudy {
            id: originalId
            slug
            title
          }
          ... on DatoCmsNewsroom {
            id: originalId
            slug
            metaTags {
              title
            }
          }
          ... on DatoCmsPressRelease {
            id: originalId
            slug
            title
          }
          ... on DatoCmsIntegrationPage {
            id: originalId
            slug
            title
          }
          ... on DatoCmsNoShowCalculator {
            id: originalId
            slug
            metaTags {
              title
            }
          }
          ... on DatoCmsBusinessValuationCalculator {
            id: originalId
            slug
            metaTags {
              title
            }
          }
        }
      }
      contentWordCounter
    }
  }
`;

export default BlogPost;
